import headlogo from "../assets/img/img.png";
import { useState } from "react";
import { useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import BinanceLogo from "../assets/img/binance-coin-header-visual.png";
import AfflicateItem from "./AfflicateItem";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  numberWithCommas,
} from "../utils/util";

const TopInfoSection = () => {
    var roiplans=[11.5,6.5,5,4.5,4];
    var daysplans=[10,20,30,40,50];

    const [amount, setAmount] = useState(1);
    const [youget, setYouget] = useState(1.15);
    const [days, setDays] = useState(0);
    const [roi, setRoi] = useState(11.5);
    const [plan, setPlan] = useState(0);
    const { handleInvest,walletBalance } = useContext(BlockchainContext);
      const {
    userInvitedInfo,
    account,
    lastDeposits,
    userTotalDeposits
  } = useContext(BlockchainContext);
    
    const handleSubmitForm = async() => {
        //event.preventDefault();
        console.log(plan);
        if(amount > 0){
          handleInvest(plan, amount);
          setAmount('')
        }

      };
    const updateamount= async(value) => {
        console.log(value);
        setAmount(value);
        if(value>0)
        {
            console.log(value,roiplans[plan],daysplans[days],plan);
            setYouget(value*roiplans[plan]*daysplans[days]/100);
        }
      };
    const setMaxAmount= async() => {
        updateamount(walletBalance-0.012)
      };
      const updatedays= async(value) => {
        
        console.log(value);
        let _roi=4;
        let _plan=0;
        let _days=10;
        _roi=roiplans[value];
        _days=daysplans[value];
        _plan=value
        setRoi(_roi);
        setPlan(_plan);
        setDays(value);
        setYouget(amount*daysplans[value]*_roi/100);
      };

  return (
    // <div className="top-info-BNBSTAKE text-center py-5">
    //   <h1>BNBSTAKE</h1>
    //   <p>#1 Community Experimental Yield Farm</p>
    //   <p>On Binance Smart Chain</p>
    // </div>
    <div className="row mainsection-content">
    <div className="col-md-7 align-center" >
        <h1>Stable & Profitable</h1>
        <h1>Yield Farming</h1>
         <p> Get started with the easiest and most secure platform</p>

    </div>
    <div className="col-md-5" >
    <img src={BinanceLogo} alt="Bnb Stake" className="logo2" />
    </div>
    <div className="calculate-block-container">
        <div className="container-info">
            <div className="header-section">
                <h2 className="title-header" id="investmentSectionTitle">
                    Calculate Profit
                </h2>
            </div>
            <div className="row">
                <div className="col-md-6 mt-5">
                    <div className="container-calculate-info">
                       
                            <div className="container-counter">
                                <div className="deposit-block deposit">
                                    <h3 className="title-deposit">
                                        Deposit Period (days):
                                    </h3>
                                    <div className="container-slider" id="slider-container">
                                        <div className="container-slider-range" id="js-slider">
                                        <select id="lang" onChange={(event) => updatedays(event.target.value)}  value={days} className="selectlayoutadjustment">
                                          <option value="0">10</option>
                                          <option value="1">20</option>
                                          <option value="2">30</option>
                                          <option value="3">40</option>
                                          <option value="4">50</option>
                                       </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-counter-amount">
                                    <div className="deposit-block">
                                        <h3 className="title-deposit amount-title">
                                            Deposit Amount:
                                        </h3>
                                        <div className="amount-field">
                                            
                                                <input type="number" id="depositAmount" className="amount-input" value={amount} min="0" onChange={(event) => updateamount(event.target.value)} />
                                                <button className="amount-field-button" onClick={setMaxAmount}>Max</button>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-counter">
                                <div className="container-footer-info">
                                    <ul className="list-info">
                                        <li className="item-info">
                                            <h4 className="title-info">
                                                Daily ROI:
                                            </h4>
                                            <p id="dailyRoi">
                                                {roiplans[days]}
                                            </p>
                                        </li>
                                        <li className="item-info">
                                            <h4 className="title-info">
                                                Total Profit:
                                            </h4>
                                            <p id="totalProfitPercent">
                                                {roiplans[days]*daysplans[days]}%
                                            </p>
                                        </li>
                                        <li className="item-info">
                                            <h4 className="title-info">
                                                In <span id="days">{daysplans[days]}</span> days
                                                you will earn:
                                            </h4>
                                            <p className="pink-text"> 
                                                <span id="profitCurrencyValue">{numberWithCommas(youget,5) }</span> <span>BNB</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <button type="button" className="pink-btn invest-btn" id="investButton" onClick={handleSubmitForm}>Invest</button>
                            </div>
                        
                    </div>
                </div>
                <div className="col-md-6 mt-5">

                    <div className="afflicate-box" id='affiliate'>
                      <div>
                        <h2 className="afflicate-head">Affiliate Program</h2>
                        <p className="afflicate-subhead">Referral Bonus: 12%</p>
                      </div>
                      <div className="afflicate-rectangle-holder d-flex align-items-end justify-content-center gap-3 mt-5 mb-3 autoresizeaffiliate">
                        <AfflicateItem level="LEVEL 1" percent="5%" type="aff1" />
                        <AfflicateItem level="LEVEL 2" percent="3%" type="aff2" />
                        <AfflicateItem level="LEVEL 3" percent="2%" type="aff3" />
                        <AfflicateItem level="LEVEL 4" percent="1%" type="aff4" />
                        <AfflicateItem level="LEVEL 5" percent="1%" type="aff4" />
                      </div>
                      <div className="personal-link-box">
                        <p className="personal-link-head mb-3">Your personal link:</p>
                        {account && userTotalDeposits>0 ? (
                          <div className="d-flex justify-content-between person-inner-box">
                            <p className="pers-link">{`https://bnbstake.io/?ref=${account}`}</p>
                            <CopyToClipboard
                              text={`https://bnbstake.io/?ref=${account}`}
                              onCopy={() => {
                                toast.success("personal link Copied Successfully");
                              }}
                            >
                              <button className="pers-copy-btn">Copy</button>
                            </CopyToClipboard>
                          </div>
                        ):(
                          <div className="d-flex justify-content-between person-inner-box">
                            <p className="pers-link">Not Activated Yet!</p>
                            <CopyToClipboard
                              text={`https://bnbstake.io`}
                              onCopy={() => {
                                toast.success("personal link Copied Successfully");
                              }}
                            >
                              <button className="pers-copy-btn">Copy</button>
                            </CopyToClipboard>
                          </div>
                        )}
                      </div>
                      <div className="row">
                          <p className="afflicate-end-text col-md-6">
                            Invited Users: {userInvitedInfo[0]}
                          </p>
                          <p className="afflicate-end-text col-md-6">
                            Total Earnings: {userInvitedInfo[1]} BNB
                          </p>
                     </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
 
  );
};

export default TopInfoSection;
