
const FooterItem = ({image,title,text}) => {
    return(
        <div className="footer-item col-xl-4 d-flex flex-column align-items-center gap-3">
        <div className="footer-item-icholder">
          <img
            src={image}
            className="foote-item-icon"
            alt="bnbstake.io"
          />
        </div>
        <h2 className="footer-item-title">{title}</h2>
        <p className="footer-item-text">
          {text}
        </p>
      </div>
    )
}
export default FooterItem