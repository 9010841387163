//import { connectors } from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
//Icons
// import BinanceIcon from '../assets/img/binance-wallet.svg'
// import MathIcon from '../assets/img/math-wallet.svg'
// import TrustIcon from '../assets/img/trust-wallet.svg'
// import SafepalIcon from '../assets/img/safepal-wallet.svg'
// import BinanceIcon from '../assets/img/binance-wallet.png'

export const providers = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed.binance.org/'
      },
      chainId: 56,
      network: "binance",
    },
  },
  // fortmatic: {
  //   package: Fortmatic,
  //   options: {
  //     // Mikko's TESTNET api key
  //     key: "pk_test_391E26A3B43A3350"
  //   }
  // },
  // torus: {
  //   package: Torus, // required
  //   options: {
  //     networkParams: {
  //       host: "https://localhost:8545", // optional
  //       chainId: 1337, // optional
  //       networkId: 1337 // optional
  //     },
  //     config: {
  //       buildEnv: "development" // optional
  //     }
  //   }
  // },
  // authereum: {
  //   package: Authereum // required
  // },
  // 'custom-math': {
  //   display: {
  //     name: 'Math',
  //     description: 'Math Wallet',
  //     logo: MathIcon,
  //   },
  //   package: 'math',
  //   connector: connectors.injected,
  // },

  // walletconnect: {
  //   package: WalletConnectProvider,
  //   options: {
  //     rpc: {
  //       1: 'https://bsc-dataseed.binance.org/',
  //       56: 'https://bsc-dataseed.binance.org/',
  //     },
  //   },
  // },
  // 'custom-binance': {
  //   display: {
  //     name: 'Binance',
  //     description: 'Binance Chain Wallet',
  //     logo: BinanceIcon,
  //   },
  //   package: 'binance',
  //   connector: async (ProviderPackage, options) => {
  //     const provider = window.BinanceChain;
  //     await provider.enable();
  //     return provider;
  //   },
  // },
  // 'custom-twt': {
  //   display: {
  //     name: 'Trust',
  //     description: 'Trust Wallet',
  //     logo: TrustIcon,
  //   },
  //   package: 'twt',
  //   connector: connectors.injected,
  // },
  // 'custom-safepal': {
  //   display: {
  //     name: 'SafePal',
  //     description: 'SafePal App',
  //     logo: SafepalIcon,
  //   },
  //   package: 'safepal',
  //   connector: connectors.injected,
  // },

};
