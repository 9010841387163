// import haze from "../assets/img/haze.png";
import certik from "../assets/img/certik.png";
import dappradar from "../assets/img/dappradar.png";
import twittericon from "../assets/img/twitter.png";
import telegram from "../assets/img/telegram.png";
import stateofdapp from "../assets/img/stateofdapp.png";
import fb from "../assets/img/fb.png";

const FooterBottomSection = () => {
  return (
    <section className="footer-bottom-section w-100 py-4">
      <div className="container">
        <div className="footer-audit-box d-flex flex-wrap justify-content-evenly align-items-center py-2">
          <a target='_blank' href='https://www.stateofthedapps.com/dapps/bnbstake-io' ><img src={stateofdapp} alt="stateofthedapps" className="footer-bottom-img" style={{width:"120px"}} /></a>
          <a target='_blank' href='https://dappradar.com/binance-smart-chain/high-risk/bnb-stake-1' ><img src={dappradar} alt="dapp radar" className="footer-bottom-img" /></a>
          <a target='_blank' href='https://twitter.com/bnbstake'><img src={twittericon} alt="twitter" className="footer-bottom-img social" /></a>
          <a target='_blank' href='https://t.me/bnbstakeio'><img src={telegram} alt="telegram" className="footer-bottom-img social" /></a>
          <a target='_blank' href='https://www.facebook.com/bnbstakeio'><img src={fb} alt="Facebook" className="footer-bottom-img social" /></a>
        </div>
        <p className="footer-BNBSTAKE">
          BNB Stake <span>BLOCKCHAIN INVESTMENT PLATFORM </span>
        </p>
        <p className="footer-BNBSTAKE-bottom">©bnbstake.io, 2021</p>
      </div>
    </section>
  );
};

export default FooterBottomSection;
